import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import {
  addTabData,
  setIsDashboard,
  setSelectedTabId,
  setTabValue,
} from "../../layout/Header/Header.store";
import { setIsRecent } from "../../layout/HomePanel/HomePanel.store";
import {
  addWorkStationData,
  updateWorkStationData,
  deleteWorkStationData,
  setIsSnackBarOpen,
} from "../../common/Common.store";
import {
  findTabIdFromWorkId,
  findWorkStationInTabs,
  getUserByEmail,
  onDragStart,
} from "../../common/Common";
import CreateEditWorkStationDialog, {
  WorkStationData,
} from "./Dialog/CreateEditWorkStationDialog";
import DeleteDialog from "../../layout/Dialog/DeleteDialog";
import WorkCard from "../../layout/WorkCard/WorkCard";
import {
  Alert,
  Box,
  Card,
  CardMedia,
  Snackbar,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";

import { setIsShared } from "../Shared/Shared.store";
import { addNotificationData } from "../../layout/Header/Notification/Notification.store";
import { ICurrentEventTypeFilterOptions } from "./MapComponents/FilterArea/FilterArea";
import styles from "./Theia.module.scss";
import { INITIAL_STATE_FOR_FILTER_PROVIDER } from "../../utils/Constants";

export interface IMapState {
  showGlobeView: boolean;
  zoomLevel: number;
  mapLatLng: {
    lat: number;
    lng: number;
  };
  date: string | null;
  mapFilters: ICurrentEventTypeFilterOptions;
  myShips: {
    synmax_ship_id: string;
    object_id: number;
    type: string;
    lat?: number;
    lng?: number;
    synmax_ship_id_1?: string;
    synmax_ship_id_2?: string;
    object_id_1?: number;
    object_id_2?: number;
    path: { startDate: string; endDate: string; color: string };
    path2?: { startDate: string; endDate: string; color: string };
    name: string;
    heading: number;
  }[];
  mySatellite: {
    synmax_ship_id: string;
    name: string;
  }[];
  myEvents: {
    synmax_ship_id: string;
    type: string;
    path: { startDate: string; endDate: string; color: string };
    name: string;
  }[];
  sidebarTabIndex: number;
  sidebarDrawer: boolean;
  shipDetailsDrawer: boolean;
  polygonFeatures: any;
  activeTabId: string | null;
}

const WorkStations = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();
  const { user } = useSelector((state: RootState) => state.theia);
  const { tabs } = useSelector((state: RootState) => state.header);
  const { searchText } = useSelector((state: RootState) => state.homePanel);
  const {
    selectedFolderId,
    allProjects,
    snackbarMessage,
    snackbarSeverity,
    isSnackBarOpen,
    users,
  } = useSelector((state: RootState) => state.common);
  const userId = user.uid;

  const [workStationName, setWorkStationName] = useState<string>("");
  const [workStationId, setWorkStationId] = useState<string>("");
  const [isCreateWorkStation, setIsCreateWorkStation] =
    useState<boolean>(false);
  const [isEditWorkStation, setIsEditWorkStation] = useState<boolean>(false);
  const [isDeleteWorkStation, setIsDeleteWorkStation] =
    useState<boolean>(false);
  const [isShareWorkStation, setIsShareWorkStation] = useState<boolean>(false);

  const addWorkStation = async (workStationData: WorkStationData | string) => {
    const { workStationName, workStationMode } =
      workStationData as WorkStationData;
    const data = {
      userId,
      folderId: selectedFolderId,
      workStationName: workStationName,
      mode: workStationMode,
      mapState: {
        showGlobeView: false,
        mapLatLng: {
          lat: 51.47,
          lng: 0.45,
        },
        polygonFeatures: '{}',
        zoomLevel: 4,
        date: "",
        mapFilters: {
          AIS: {
            checked: true,
            noInfoShips: false,
            noImoShips: false,
            sanctionedShips: false,
            markerColor: "#00EB6C",
            length: [0, 500],
            width: [0, 100],
            country: { code: "", label: "" },
            type: "None",
            iconStringNormal: "data:image/svg+xml;base64," + btoa(`<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="#00EB6C" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
</svg>

              `),
          },
          light: {
            checked: true,
            providerFilters: INITIAL_STATE_FOR_FILTER_PROVIDER,
            noInfoShips: false,
            noImoShips: false,
            sanctionedShips: false,
            markerColor: "#0094FF",
            iconStringNormal: "data:image/svg+xml;base64," + btoa(`<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="#0094FF" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M8.99453 16.1988L8.70042 15.8976L9.05429 15.5128C9.48062 15.0416 9.67182 14.6509 9.7621 14.0685C9.88313 13.2365 9.60721 12.4076 9.01115 11.7972L8.70136 11.4799L9.00255 11.1858L9.30373 10.8917L9.58999 11.1849C10.2409 11.8515 10.5992 12.7647 10.5839 13.7211C10.5685 14.6775 10.2862 15.3846 9.63857 16.1112L9.28863 16.5L8.99453 16.1988Z" fill="white"/>
<path d="M5.00742 16.1988L5.30153 15.8976L4.94767 15.5128C4.52133 15.0416 4.33014 14.6509 4.23986 14.0685C4.11883 13.2365 4.39475 12.4076 4.9908 11.7972L5.30059 11.4799L4.99941 11.1858L4.69822 10.8917L4.41196 11.1849C3.76101 11.8515 3.40274 12.7647 3.41808 13.7211C3.43342 14.6775 3.7157 15.3846 4.36338 16.1112L4.71332 16.5L5.00742 16.1988Z" fill="white"/>
<circle cx="6.99951" cy="13.75" r="2" fill="white"/>
</svg>
`),
            iconStringSanctioned: "data:image/svg+xml;base64," + btoa(`<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="#0094FF"  stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M8.99453 16.1988L8.70042 15.8976L9.05429 15.5128C9.48062 15.0416 9.67182 14.6509 9.7621 14.0685C9.88313 13.2365 9.60721 12.4076 9.01115 11.7972L8.70136 11.4799L9.00255 11.1858L9.30373 10.8917L9.58999 11.1849C10.2409 11.8515 10.5992 12.7647 10.5839 13.7211C10.5685 14.6775 10.2862 15.3846 9.63857 16.1112L9.28863 16.5L8.99453 16.1988Z" fill="white"/>
<path d="M5.00742 16.1988L5.30153 15.8976L4.94767 15.5128C4.52133 15.0416 4.33014 14.6509 4.23986 14.0685C4.11883 13.2365 4.39475 12.4076 4.9908 11.7972L5.30059 11.4799L4.99941 11.1858L4.69822 10.8917L4.41196 11.1849C3.76101 11.8515 3.40274 12.7647 3.41808 13.7211C3.43342 14.6775 3.7157 15.3846 4.36338 16.1112L4.71332 16.5L5.00742 16.1988Z" fill="white"/>
<circle cx="6.99951" cy="13.75" r="2" fill="white"/>
<path d="M12.6763 21.5L3.17627 7.5" stroke="#111326" stroke-width="1.5"/>
</svg>
`),
            length: [0, 500],
            width: [0, 100],
            country: { code: "", label: "" },
            type: "None",
          },
          dark: {
            checked: true,
            providerFilters: {
              ...INITIAL_STATE_FOR_FILTER_PROVIDER,
              sentinel2: false,
            },
            noInfoShips: false,
            noImoShips: false,
            sanctionedShips: false,
            markerColor: "#FFA949",
            length: [0, 500],
            width: [0, 100],
            country: { code: "", label: "" },
            type: "None",
            iconStringNormal: "data:image/svg+xml;base64," + btoa(`<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="#FFA500" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M8.99453 16.1988L8.70042 15.8976L9.05429 15.5128C9.48062 15.0416 9.67182 14.6509 9.7621 14.0685C9.88313 13.2365 9.60721 12.4076 9.01115 11.7972L8.70136 11.4799L9.00255 11.1858L9.30373 10.8917L9.58999 11.1849C10.2409 11.8515 10.5992 12.7647 10.5839 13.7211C10.5685 14.6775 10.2862 15.3846 9.63857 16.1112L9.28863 16.5L8.99453 16.1988Z" fill="#111326"/>
<path d="M5.00742 16.1988L5.30153 15.8976L4.94767 15.5128C4.52133 15.0416 4.33014 14.6509 4.23986 14.0685C4.11883 13.2365 4.39475 12.4076 4.9908 11.7972L5.30059 11.4799L4.99941 11.1858L4.69822 10.8917L4.41196 11.1849C3.76101 11.8515 3.40274 12.7647 3.41808 13.7211C3.43342 14.6775 3.7157 15.3846 4.36338 16.1112L4.71332 16.5L5.00742 16.1988Z" fill="#111326"/>
<circle cx="6.99951" cy="13.75" r="2" fill="#111326"/>
</svg>
`),
iconStringSanctioned: "data:image/svg+xml;base64," + btoa(`<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="#FFA500" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M8.99453 16.1988L8.70042 15.8976L9.05429 15.5128C9.48062 15.0416 9.67182 14.6509 9.7621 14.0685C9.88313 13.2365 9.60721 12.4076 9.01115 11.7972L8.70136 11.4799L9.00255 11.1858L9.30373 10.8917L9.58999 11.1849C10.2409 11.8515 10.5992 12.7647 10.5839 13.7211C10.5685 14.6775 10.2862 15.3846 9.63857 16.1112L9.28863 16.5L8.99453 16.1988Z" fill="#111326"/>
<path d="M5.00742 16.1988L5.30153 15.8976L4.94767 15.5128C4.52133 15.0416 4.33014 14.6509 4.23986 14.0685C4.11883 13.2365 4.39475 12.4076 4.9908 11.7972L5.30059 11.4799L4.99941 11.1858L4.69822 10.8917L4.41196 11.1849C3.76101 11.8515 3.40274 12.7647 3.41808 13.7211C3.43342 14.6775 3.7157 15.3846 4.36338 16.1112L4.71332 16.5L5.00742 16.1988Z" fill="#111326"/>
<circle cx="6.99951" cy="13.75" r="2" fill="#111326"/>
<path d="M12.6763 21.5L3.17627 7.5" stroke="#111326" stroke-width="1.5"/>
</svg>

  `)
          },
          spoofing: {
            checked: true,
            noInfoShips: false,
            noImoShips: false,
            sanctionedShips: false,
            markerColor: "#FF6D99",
            length: [0, 500],
            width: [0, 100],
            country: { code: "", label: "" },
            subType: {
              "ais-spoofing": true,
              "footprint-spoofing": true,
              "optical-spoofing": true,
            },
            type: "None",
            iconStringNormal: "data:image/svg+xml;base64," + btoa(`<svg width="105" height="100" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="11" y="1.5" width="12.7282" height="12.7282" transform="rotate(45 11 1.5)" fill="#FF6D99" stroke="#111326" stroke-width="1.5"/>
<path d="M11 11.25V7" stroke="#111326" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="11" cy="14" r="1" fill="#111326"/>
</svg>`)
          },
          unattributed: {
            checked: true,
            providerFilters: INITIAL_STATE_FOR_FILTER_PROVIDER,
            noInfoShips: false,
            noImoShips: false,
            sanctionedShips: false,
            markerColor: "#FA5849",
            length: [100, 500],
            width: [0, 100],
            country: { code: "", label: "" },
            type: "None",
            heading: [0, 360],
            isMoving: "none",
            iconStringNormal: "data:image/svg+xml;base64," + btoa(`<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="#F75349" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
<circle cx="6.97607" cy="13" r="2.5" stroke="#111326" stroke-width="1.5"/>
<path d="M9.31696 17.9748C9.54407 18.3212 10.009 18.4179 10.3554 18.1908C10.7018 17.9637 10.7985 17.4987 10.5714 17.1523L9.31696 17.9748ZM7.6721 15.4659L9.31696 17.9748L10.5714 17.1523L8.92653 14.6435L7.6721 15.4659Z" fill="#111326"/>
</svg>

              `),
          },
          bunkering: {
            checked: true,
            noInfoShips: false,
            noImoShips: false,
            sanctionedShips: false,
            markerColor: "#55EFE6",
            length: [0, 500],
            width: [0, 100],
            country: { code: "", label: "" },
            type: "None",
          },
          sanctioned: {
            checked: true,
            noInfoShips: false,
            noImoShips: false,
            sanctionedShips: false,
            markerColor: "#F4588E",
            length: [0, 500],
            width: [0, 100],
            country: { code: "", label: "" },
            type: "None",
            iconStringNormal: "data:image/svg+xml;base64," + btoa(`<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="#D256FE" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M12.6763 21.5L3.17627 7.5" stroke="#111326" stroke-width="1.5"/>
</svg>
`),
          },
        },
        myShips: [],
        myEvents: [],
        mySatellite: [],
        sidebarTabIndex: 0,
        sidebarDrawer: false,
        shipDetailsDrawer: false,
      } as any,
    };
    dispatch(addWorkStationData(data));
  };

  const editWorkStation = async (workStationData: WorkStationData | string) => {
    const { workStationName, workStationMode } =
      workStationData as WorkStationData;
    for (const key in tabs) {
      if (
        tabs[key].id ===
        allProjects[selectedFolderId].workStations[workStationId].tabId
      ) {
        const data = {
          userId,
          folderId: selectedFolderId,
          workStationId,
          newWorkStationName: workStationName,
          tabId: key,
        };
        dispatch(updateWorkStationData(data));
        return;
      }
    }
    const data = {
      userId,
      folderId: selectedFolderId,
      workStationId,
      newWorkStationName: workStationName,
      newWorkStationMode: workStationMode,
    };
    dispatch(updateWorkStationData(data));
  };

  const deleteWorkStation = async () => {
    for (const key in tabs) {
      if (
        tabs[key].id ===
        allProjects[selectedFolderId].workStations[workStationId].tabId
      ) {
        const data = {
          userId,
          folderId: selectedFolderId,
          workStationId,
          tabId: key,
        };
        dispatch(deleteWorkStationData(data));
        return;
      }
    }
    const data = {
      userId,
      folderId: selectedFolderId,
      workStationId,
    };
    dispatch(deleteWorkStationData(data));
  };

  const addTabList = (name: string, workId: string) => {
    const workStationData = findWorkStationInTabs(workId, tabs);
    const selectedTabId = findTabIdFromWorkId(tabs, workId);
    const selectedWorkStation =
      allProjects[selectedFolderId].workStations[workId];
    const updateDatedata = {
      userId,
      folderId: selectedFolderId,
      workStationId: workId,
      isWorkStationDate: true,
      mapState: selectedWorkStation.mapState as IMapState,
    };

    if (workStationData) {
      dispatch(setTabValue(workStationData.value));
      dispatch(setSelectedTabId(selectedTabId));
    } else {
      const maxTabValue =
        Object.keys(tabs).length > 0
          ? Math.max(...Object.values(tabs).map((item) => item.value)) + 1
          : 0;
      const newTab = {
        userId,
        tabName: name,
        tabValue: maxTabValue,
        folderId: selectedFolderId,
        workStationId: workId,
      };
      dispatch(addTabData(newTab));
      dispatch(setTabValue(maxTabValue));
    }
    dispatch(setIsDashboard(false));
    dispatch(setIsRecent(false));
    dispatch(setIsShared(false));
    dispatch(updateWorkStationData(updateDatedata));
  };

  const shareWorkStation = async (userEmail: string | WorkStationData) => {
    const emailUserId =  await  getUserByEmail(users, userEmail.toString());
    const shareWorkData = {
      userId: emailUserId as string,
      workStationName,
      sharedUserId: userId,
      type: "share",
    };
    dispatch(addNotificationData(shareWorkData));
  };

  const handleClose = () => {
    dispatch(setIsSnackBarOpen(false));
  };

  const renderWorkCard = (work: string) => (
    <Box
      key={work}
      onDragStart={(e) =>
        onDragStart(
          e,
          allProjects[selectedFolderId].workStations[work].name,
          selectedFolderId
        )
      }
      draggable
    >
      <WorkCard
        key={work}
        work={work}
        workStationName={allProjects[selectedFolderId].workStations[work].name}
        thumbnail={allProjects[selectedFolderId].workStations[work].thumbnail}
        setWorkStationName={setWorkStationName}
        setWorkStationId={setWorkStationId}
        setIsEditWorkStation={setIsEditWorkStation}
        setIsDeleteWorkStation={setIsDeleteWorkStation}
        setIsShareWorkStation={setIsShareWorkStation}
        addTabList={addTabList}
      />
    </Box>
  );

  return (
    <Box display="flex" flexDirection="column" className={styles.homeMain}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        position="sticky"
        top="0"
        className={styles.homeWrap}
      >
        {!allProjects.msg && Object.keys(allProjects).length > 0 ? (
          <Box className={styles.homeDashbordMain}>
            <Box
              sx={{
                gridTemplateColumns: {
                  xl: "repeat(4,1fr)",
                  lg: "repeat(3,1fr)",
                  md: "repeat(2,1fr)",
                },
              }}
              display="grid"
              gridTemplateColumns="repeat(3,1fr)"
              gap={3}
              className={styles.homeDashbord}
            >
              <Card
                onClick={() => setIsCreateWorkStation(true)}
                className={styles.homeDashbordCard}
              >
                <CardMedia className={styles.dashbordCardAdd}>
                  <Add className={styles.CardAddIcon} />
                  <Typography variant="h6" className={styles.CardAddText}>
                    Create New Work Station
                  </Typography>
                </CardMedia>
              </Card>
              {searchText
                ? Object.keys(allProjects)
                    .flatMap((data: string) =>
                      Object.keys(allProjects[data].workStations)
                        .filter((work: string) =>
                          allProjects[data].workStations[work].name
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                        )
                        .map((work: string) => ({
                          workId: work,
                          workStationData: allProjects[data].workStations[work],
                        }))
                    )
                    .map(
                      ({ workId, workStationData }) =>
                        workId &&
                        workStationData && (
                          <Box
                            key={workId}
                            onDragStart={(e) =>
                              onDragStart(
                                e,
                                workStationData.name,
                                selectedFolderId
                              )
                            }
                            draggable
                          >
                            <WorkCard
                              key={workId}
                              work={workId}
                              workStationName={workStationData.name}
                              thumbnail={workStationData.thumbnail}
                              setWorkStationName={setWorkStationName}
                              setWorkStationId={setWorkStationId}
                              setIsEditWorkStation={setIsEditWorkStation}
                              setIsDeleteWorkStation={setIsDeleteWorkStation}
                              setIsShareWorkStation={setIsShareWorkStation}
                              addTabList={addTabList}
                            />
                          </Box>
                        )
                    )
                : Object.keys(
                    allProjects[selectedFolderId]?.workStations || {}
                  ).map(renderWorkCard)}
            </Box>
          </Box>
        ) : (
          <Typography variant="h6" className={styles.CardAddText}>
            Please create folder first
          </Typography>
        )}
        {isSnackBarOpen && (
          <Snackbar
            open={isSnackBarOpen}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              onClose={handleClose}
              severity={snackbarSeverity}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        )}
        {isCreateWorkStation && (
          <CreateEditWorkStationDialog
            open={isCreateWorkStation}
            setOpen={setIsCreateWorkStation}
            name="work_station_name"
            label="Work Station Name"
            title="Create New Work Station"
            buttonName="Create"
            addEditFolder={addWorkStation}
            isEditDialog={false}
          />
        )}
        {isEditWorkStation && (
          <CreateEditWorkStationDialog
            open={isEditWorkStation}
            setOpen={setIsEditWorkStation}
            name="work_station_name"
            label="Work Station Name"
            title="Edit Work Station Name"
            buttonName="Change"
            addEditFolder={editWorkStation}
            oldWorkStationName={workStationName}
          />
        )}
        {isDeleteWorkStation && (
          <DeleteDialog
            open={isDeleteWorkStation}
            setOpen={setIsDeleteWorkStation}
            deleteFolderWorkStation={deleteWorkStation}
          />
        )}
        {isShareWorkStation && (
          <CreateEditWorkStationDialog
            open={isShareWorkStation}
            setOpen={setIsShareWorkStation}
            name="user_email"
            label="Enter User Email"
            title="User Email"
            buttonName="Send"
            addEditFolder={shareWorkStation}
          />
        )}
      </Box>
    </Box>
  );
};

export default WorkStations;
