export const getToolTipDeckGl = ({ layer, object }: any) => {
  return object && layer && layer?.id === "droppedPins" && object.properties
    ? {
        html: `<div>
        <p style='display: flex; align-items: center; gap: 5px; padding: 5px; color:#fff;'>Point: ${object.properties?.title}</p>
        <p style='display: flex; align-items: center; gap: 5px; padding: 5px; color:#fff;'>lat:   ${object.properties?.lat}</p>
        <p style='display: flex; align-items: center; gap: 5px; padding: 5px; color:#fff;'>lng:   ${object.properties?.lng}</p>
       </div> `,
        style: {
          fontSize: "0.8em",
          padding: "15px",
          backgroundColor: "#24263c",
          minWidth: "100px",
          gap: "5px",
        },
      }
    : {
        style: {
          display: "none",
        },
      };
};

export const defaultImplementation = () => {
  throw new Error("Function not implemented");
};
