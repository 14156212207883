import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { defaultImplementation } from "../../../../utils/utilis";
import {
  getObjectsForShips,
  getSatelliteImage,
} from "../../RenderShips/apiCalls";
import { ShipImages } from "../../MainMap";
import { SatelliteObject } from "../../../../types/types";

const NAMEID = "NAMEID";

interface ISatelliteTimelineProviderProps {
  children: React.ReactNode;
}

interface State {
  id: string;
  name: string;
}

type SatelliteImages = {
  [key: string]: ShipImages[];
};

type Action = { type: typeof NAMEID; name: string; id: string };

interface ISatelliteTimelineContext {
  isSatelliteImageLoading: boolean;
  satelliteImageTimeline: SatelliteImages;
  shouldFetchSatelliteImage: (id: string, name: string) => void;
  setSatelliteImageTimeline: React.Dispatch<
    React.SetStateAction<SatelliteImages>
  >;
  setIsSatelliteImageLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const SatelliteTimelineContext = createContext<ISatelliteTimelineContext>({
  isSatelliteImageLoading: false,
  satelliteImageTimeline: {},
  shouldFetchSatelliteImage: defaultImplementation,
  setSatelliteImageTimeline: defaultImplementation,
  setIsSatelliteImageLoading: defaultImplementation,
});

const initialState = {
  id: "",
  name: "",
};

const useSatelliteImageTimeline = () => {
  const context = useContext<ISatelliteTimelineContext>(
    SatelliteTimelineContext
  );
  if (!context) {
    throw new Error("useWells must be used with a WellsProvider");
  }
  return context;
};

const satelliteReducer = (state: State, action: Action) => {
  switch (action.type) {
    case NAMEID:
      return { ...state, id: action.id, name: action.name };
  }
};

/**
 * Purpose of this Provider is to handle all various satellite image timelines we are fetching.
 * This allows us to cache so that we are not constantly refetching images
 */
export const SatelliteTimelineProvider = ({
  children,
}: ISatelliteTimelineProviderProps) => {
  const [isSatelliteImageLoading, setIsSatelliteImageLoading] =
    useState<boolean>(false);
  const [satelliteImageTimeline, setSatelliteImageTimeline] =
    useState<SatelliteImages>({});
  const [state, dispatch] = useReducer(satelliteReducer, initialState);

  const shouldFetchSatelliteImage = (id: string, name: string) => {
    if (satelliteImageTimeline[`sat:${id}`]) {
      return;
    } else {
      dispatch({
        type: NAMEID,
        id: id,
        name: name,
      });
    }
  };

  useEffect(() => {
    const fetchObjectsForShips = async () => {
      const id = state.id;
      if (id && state.name) {
        try {
          setIsSatelliteImageLoading(true);
          const shipObjects = await getObjectsForShips([id]);

          await Promise.all(
            shipObjects.map(async (item: SatelliteObject) => {
              const data = await getSatelliteImage(item.object_id);

              if (data) {
                setSatelliteImageTimeline(
                  (prevState: { [key: string]: ShipImages[] }) => {
                    const updatedState = { ...prevState };

                    updatedState[`sat:${id}`] =
                      updatedState[`sat:${id}`]?.length === 1 &&
                      updatedState[`sat:${id}`][0].image === ""
                        ? []
                        : updatedState[`sat:${id}`] || [];

                    updatedState[`sat:${id}`].push({
                      object_id: item.object_id,
                      longitude: item.longitude,
                      latitude: item.latitude,
                      acquired: item.acquired,
                      image: data,
                      name: state.name,
                      id: `sat:${id}`,
                    });
                    return updatedState;
                  }
                );
                setIsSatelliteImageLoading(false);
              }
            })
          );
        } catch (error) {
          console.error("Error fetching satellite images:", error);
        } finally {
          setIsSatelliteImageLoading(false);
        }
      }
    };

    fetchObjectsForShips();
  }, [state.id, state.name]);

  return (
    <SatelliteTimelineContext.Provider
      value={{
        isSatelliteImageLoading,
        satelliteImageTimeline,
        shouldFetchSatelliteImage,
        setSatelliteImageTimeline,
        setIsSatelliteImageLoading,
      }}
    >
      {children}
    </SatelliteTimelineContext.Provider>
  );
};

export default useSatelliteImageTimeline;
