import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import LayoutWrapper from "../../layout/LayoutWrapper/LayoutWrapper";
import Map from "./MainMap";
import WorkStations from "./WorkStations";
import Recent from "../Recent/Recent";
import Shared from "../Shared/Shared";
import { SatelliteTimelineProvider } from "./ShipDetails/hooks/useSatelliteImageTimeline";

const Theia = () => {
  const { isDashboard } = useSelector((state: RootState) => state.header);
  const { isRecent } = useSelector((state: RootState) => state.homePanel);
  const { isShared } = useSelector((state: RootState) => state.shared);

  return (
    <>
      {isDashboard && !isRecent && !isShared ? (
        <WorkStations />
      ) : isRecent && isDashboard && !isShared ? (
        <Recent />
      ) : isShared && !isRecent && isDashboard ? (
        <Shared />
      ) : (
        <SatelliteTimelineProvider>
          <Map />
        </SatelliteTimelineProvider>
      )}
    </>
  );
};

export default Theia;
